import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';


function App() {
  const [text, setText] = useState("H");
  const [timer, setTimer] = useState(0);
  const phrase = "Hi, I'm Siddhartha ";
  const [projIdx, setProjIdx] = useState(0);
  const [prevProjIdx, setPrevProjIdx] = useState(-1);
  const [inTransitionWorking, setIntransitionWorking] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const projects = [
    { 
      name: "3D Art Project",
      date: "October 2024",
      descr: "I worked on this wood project that represents the intersection of my two cultures: depicted through the crossing of my names written in English and Bengali",
      img: "/name.jpg"
    },
    {
      name: "Research Paper",
      date: "August 2023 - July 2024",
      descr:`This research analyzed the efficacy of Large Language Models (LLMs) in generating Smart Contract code to run on the Blockchain. With LLMs being a growing technology and increasingly being used for code generation, the purpose of the research was to see how this skill would transfer to Smart Contract code - a largely unexplored area with lots of potential. These programs are unalterable so it is highly important for it to meet stringent standards.

      I prompt-engineered several "tasks" for the LLMs to complete. After that, I programmed and simulated a test environment for the code to run on the Blockchain using JavaScript. I then evaluated the success of the code in this environment under the metrics of accuracy, quality, and efficiency.
      
      I determined that none of the LLMs were at a viable point for industry use currently, however they showed potential to be able to complete simple tasks which could aid in auditing code.
      
      Published in 2025 Future of Information & Communication Conference and submitted to Regeneron STS
      `,
      img:"/paper.png",
      link: "https://arxiv.org/abs/2407.11019"
    },
    {
      name: "Alphag3n",
      date: "Jan 2022 - Present",
      descr: `Became Lead Solidity Engineer, helped organize conference at Stanford inviting over 100 high schoolers to learn about Blockchain and AI technology, interviewed industry experts
        `,
        img: "/alphag3n.png",

      link: "https://www.alphag3n.com/",

    },
    {
      name: "Internship @ Ema",
      date: "June - July 2024",
      descr: 'In-person interned as a Software Engineer at Generative AI company, analyzed different approaches in searching for data in PDFs',
      img: "/ema.png"
    }, 
    {
      name: "Internship @ BuidlersTribe",
      date: "July 2022 - June 2024",
      img: "/budilerstribe.png",
      descr: `Interned remotely as a Software Engineer at start up, developed NFT-based verification system for Discord server
      `
    },
    {
      name: "COSMOS STEM program",
      date: "July 2023",
      descr: "Did a Summer Program studying Quantum Information Science and Engineering offered by UC Santa Cruz in the COSMOS STEM program. Researched on Quantum Dots",
      img: "/cosmos.jpg"
    },
    
  ]
  useEffect(() => {
    setTimeout(() => {
      if (text.length != phrase.length) {
        setText(prev => prev + phrase[prev.length])
      }
      setTimer(prev => prev + 1);
      // if (timer % 50 == 0 && !inTransitionWorking) {
        
      //   setProjIdx(prev => (prev + 1) % projects.length);
      //   setPrevProjIdx(projIdx);
      //   setIntransitionWorking(true);
      //   setTimeout(() => {
      //     setIntransitionWorking(false);
      //   }, 2000)
      // }
    }, 100);

  }, [timer])
  return (
    <div className='App' id = "app"  onScroll = {(e) => {
      if (document.getElementById("app").scrollTop > document.getElementById("main").offsetHeight && document.getElementById("app").scrollTop > lastScroll && document.getElementById("j").scrollTop < document.getElementById("j").offsetHeight) {
        document.getElementById("app").scrollTo({top: document.getElementById("j").offsetTop - document.getElementById("nav").offsetHeight, behavior: "smooth"})
      }
      setLastScroll(document.getElementById("app").scrollTop)
    }}>

      <div className='navbar' id = "nav">
        <div> Siddhartha Chatterjee </div>
        <nav>
          <a href = "/resume.pdf" target = "_blank"> Resume </a>
          <a href = "mailto:siddhartha.chatterjee210@gmail.com"> Contact </a>
        </nav>
      </div>
      <main className = "main" id = "main">
        <div className = 'fade' id = "text">
          <img src = "/picture.jpg" />
          <h1 > {text} </h1>
          <hr />
          <p className='fade'>
            I'm a current senior at Mountain View High School in Mountain View, CA. I'm  interested in Blockchain and AI technology. Outside of school, I love to do Martial Arts, go hiking, and play chess. Currently, I'm in the midst of college applications. I'm still working on this page so feel free to look around.
          </p>
        </div>
      </main>
      <div className={`page2 `} id = "j" onScroll={() => {
       // console.log(document.getElementById("j").scrollTop)
     // if (document.getElementById("j").scrollTop > (document.getElementById("j").offsetHeight * projIdx) * .8) {
    //     setIntransitionWorking(true);
      
       setProjIdx(prev => Math.max(0, Math.round((document.getElementById("j").scrollTop - document.getElementById("h").offsetHeight) / (document.getElementById("j").offsetHeight ))));
    //     setPrevProjIdx(projIdx);
    //     setTimer(0);
    // //    document.getElementById("j").scrollTo({top:0, behavior:"smooth"});
    //     document.getElementById("app").scrollTo({top: document.getElementById("j").offsetTop - document.getElementById("nav").offsetHeight, behavior: "smooth"})
    //     setTimeout(() => {
    //       setIntransitionWorking(false);
        
    //     }, 2000)
      // }
      // else if (prevProjIdx < 0) {
      //   // setPrevProjIdx(0);
      //   // setIntransitionWorking(true);
      //   // setTimeout(() => {
      //   //   setIntransitionWorking(false);
          
      //   // }, 2000)
      // }
      document.getElementById("app").scrollTo({top: document.getElementById("j").offsetTop - document.getElementById("nav").offsetHeight, behavior: "smooth"})
    }} >
           
          <header id = "h" style = {{position: "sticky", top: 0}}>

            <h2> What I've been working on: </h2>
           
            {/* <p style={{fontSize:11}}> Viewing {projIdx + 1} / {projects.length} </p> */}
            <div className='buttons'>

            {projects.map((_, i) => (
              <button onClick = {() => {
                setIntransitionWorking(true);
                setProjIdx(i)
              //  setPrevProjIdx(projIdx);
                document.getElementById("j").scrollTo({top: i * document.getElementById("j").offsetHeight, behavior: "smooth"})
                setTimer(0);
                setTimeout(() => {
                  setIntransitionWorking(false);
                
                }, 2000)
              }} className={projIdx == i && "active"}>
                
              </button>
            ))}
            </div>
          </header>
            <div className={`project-container ${inTransitionWorking && "transition"}`} style={{height: (projects.length )* document.getElementById("j")?.offsetHeight}}>
              
              {projects.map((_, i) => i).map(idx =>  idx >= 0 && (idx != prevProjIdx || inTransitionWorking) && (

                <div className={`project ${inTransitionWorking && ""}`}style = {{position: "sticky", top: document.getElementById("h")?.offsetHeight}}>
                  <h3>
                  {projects[idx].name} • {projects[idx].date}
                  </h3>
                  <div className='content'>
                  <p>
                  
                    {projects[idx].descr}
                
                  {projects[idx].link && (<><br/> <br/>
                  <a href = {projects[idx].link}> Link </a>  </>)}
                  </p>
                  <img src = {projects[idx].img} />
                  </div>
                </div>
              
              ))}
              <div style = {{height: 500}}>
                hi
              </div>
            
            </div>
          
      </div>
      <footer> Copyright (C) Siddhartha Chatterjee 2024. </footer>
    </div>
  );
}

export default App;
